//  fonts

$path: "./assets/fonts/interface/";

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-thin.woff") format("woff"),
    url("#{$path}interface-thin.ttf") format("truetype");
  font-style: normal;
  font-weight: 200;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-thin-italic.woff") format("woff"),
    url("#{$path}interface-thin-italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 200;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-light.woff") format("woff"),
    url("#{$path}interface-light.ttf") format("truetype");
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-light-italic.woff") format("woff"),
    url("#{$path}interface-light-italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 300;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-regular.woff") format("woff"),
    url("#{$path}interface-regular.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-regular-italic.woff") format("woff"),
    url("#{$path}interface-regular-italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-bold.woff") format("woff"),
    url("#{$path}interface-bold.ttf") format("truetype");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-bold-italic.woff") format("woff"),
    url("#{$path}interface-bold-italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 600;
}

@font-face {
  font-family: "interface bold";
  src: url("#{$path}interface-xbold.woff") format("woff"),
    url("#{$path}interface-xbold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-xbold.woff") format("woff"),
    url("#{$path}interface-xbold.ttf") format("truetype");
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "interface";
  src: url("#{$path}interface-xbold-italic.woff") format("woff"),
    url("#{$path}interface-xbold-italic.ttf") format("truetype");
  font-style: italic;
  font-weight: 700;
}
