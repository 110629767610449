@import "./style/reset.scss";
@import "./style/fonts.scss";
@import "./style/vars.scss";
@import "./style/mixins.scss";

// styling
body {
	font-family: $font-n;
	font-size: 16px;
	line-height: 28px;
}

.container {
	margin: 0 auto;
	max-width: 1440px;
}

header {
	height: 413px;
	background-image: url("./assets/yx-bg.jpg");
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;

	@include res(mobile) {
		height: auto;
	}

	.container {
		padding: 25px 34px 60px $padding;

		@include res(tab-l) {
			padding: 25px 34px 60px $padding-medium;
		}

		@include res(tab-p) {
			padding: 25px 34px 60px $padding-small;
		}
	}

	.logo {
		display: table;
		margin-left: auto;

		@include res(tab-p) {
			img {
				max-width: 100px;
				width: 100%;
				margin-bottom: 30px;
			}
		}
	}

	h1 {
		font-family: $font-b;
		font-size: 32px;
		color: $white;
		letter-spacing: 0;
		margin-bottom: 20px;
		line-height: 41px;

		@include res(tab-p) {
			font-size: 26px;
			line-height: 32px;
		}

		@include res(mobile) {
			font-size: 22px;
		}

		span {
			display: block;
			font-family: $font-b;
			font-size: 16px;

			@include res(mobile) {
				font-size: 15px;
				line-height: 22px;
				margin-top: 16px;
			}
		}
	}

	ul {
		list-style: none;
		color: $white;

		li {
			&::before {
				content: "✓";
				margin-right: 7px;
			}

			@include res(mobile) {
				font-size: 14px;
			}
		}
	}
}

#main {
	padding: 96px $padding 0px;
	position: relative;
	display: block;

	@include res(tab-l) {
		padding: 96px $padding-medium 0px;
	}
	@include res(tab-p) {
		padding: 56px $padding-small 0px;
	}

	&::after {
		content: "";
		position: absolute;
		background: $blue;
		left: 24px;
		width: calc(100% - 48px);
		height: 100%;
		z-index: -1;
		top: 24px;

		@include res(mobile) {
			width: calc(100%);
			left: 0;
			right: 0;
		}
	}

	img.people {
		max-width: 480px;
		width: 100%;
		height: 280px;
		position: absolute;
		top: -4%;
		left: 50%;
		transform: translateX(-50%);
		object-fit: contain;

		@include res($width: 600) {
			width: 90%;
			top: -5%;
		}
	}

	.mc-status {
		font-size: 14px;
		margin-bottom: 16px;
	}

	div.mce_inline_error {
		font-size: 12px;
		color: $red;
	}

	form {
		max-width: 700px;
		width: 100%;
		position: relative;
		margin: 0 auto;
		z-index: 1;
		padding: 32px 152px 80px;
		background: $white;
		border-radius: 20px;
		color: $blue;

		@include res(tab-l) {
			padding: 32px 100px 80px;
		}

		@include res(tab-p) {
			max-width: 480px;
			padding: 32px 60px 80px;
		}

		@include res($width: 600) {
			max-width: 380px;
			padding: 32px 40px 80px;
		}

		@include res(mobile) {
			padding: 32px 30px 80px;
		}

		h2 {
			font-size: 24px;
			margin-bottom: 15px;
			font-family: $font-b;
			max-width: 380px;
			width: 100%;
			text-align: left;

			@include res($width: 600) {
				font-size: 20px;
				margin-bottom: 12px;
			}
		}

		> p {
			margin-top: 0;
			margin-bottom: 40px;
			color: $blue;
			max-width: 380px;
			width: 100%;
			text-align: left;
			margin-left: 0;

			@include res($width: 600) {
				margin-bottom: 30px;
			}
		}

		.input-group {
			display: flex;
			flex-direction: column;
			max-width: 380px;
			width: 100%;
			margin-bottom: 41px;

			label {
				text-transform: uppercase;
				font-size: 12px;
				font-family: $font-b;
				margin-bottom: 16px;

				@include res(mobile) {
					margin-bottom: 6px;
				}

				a {
					color: $grey;
				}
			}

			input,
			textarea {
				border: none;
				padding: 0;
				padding-bottom: 15px;
				font-size: 21px;
				border-bottom: 1px solid rgba(13, 34, 67, 0.24);
				height: 45px;
				font-family: $font;
				color: $blue;

				@include res(tab-p) {
					font-size: 16px;
					height: 38px;
				}

				@include res(mobile) {
					height: 34px;
					padding-bottom: 0px;
				}

				&::placeholder {
					font-family: $font;
					font-size: 21px;
					color: rgba($color: $blue, $alpha: 0.2);

					@include res(tab-p) {
						font-size: 16px;
					}
				}

				&:active,
				&:focus {
					outline: none;
					border-bottom: 1px solid rgba(13, 34, 67);
				}
			}

			textarea {
				height: auto;
			}

			.error-message {
				color: $red;
				font-size: 11px;
			}
		}

		button[type="submit"] {
			appearance: none;
			border: none;
			display: table;
			margin-left: auto;
			color: $white;
			background: $red;
			cursor: pointer;
			padding: 15px 35px;
			transition: border 2ms ease-in;
			position: relative;
			border-radius: 3px;

			&::after {
				content: "";
				background: none;
				position: absolute;
				top: 0;
				left: 0;
				width: calc(100% - 6px);
				height: calc(100% - 6px);
				border: 3px solid $blue;
				opacity: 0;
				border-radius: 3px;

				transition: opacity 350ms ease-in;
			}

			&:hover::after {
				opacity: 1;
			}
		}
	}

	#mce-error-response {
		color: $red;
	}

	#mce-success-response {
		color: #2ea15e;
		margin-bottom: 24px;
	}

	.field-shift {
		left: -9999px;
		position: absolute;
	}
}

footer {
	margin: 60px auto 40px;

	.grid {
		display: flex;
		margin-top: 60px;

		@include res(tab-p) {
			flex-direction: column;
		}
	}

	.grid-item {
		width: 33.333%;
		border-right: 1px solid #e8ebed;
		text-align: center;
		padding-top: 80px;
		padding-bottom: 95px;

		@include res(tab-p) {
			width: 100%;
			border-right: none;
			border-bottom: 1px solid #e8ebed;
			padding-top: 35px;
			padding-bottom: 35px;
		}

		&:last-of-type {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				background-image: url("./assets/text.png");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				width: 150px;
				height: 70px;
				top: 20%;
				left: 50%;
				transform: translate(-50%, -50%);

				@include res(tab-p) {
					display: none;
				}
			}
		}

		a {
			font-family: $font-b;
			font-size: 16px;
			color: $blue;
			text-decoration: none;
			margin-right: 15px;
			position: relative;

			&::after {
				content: "";
				position: absolute;
				background-image: url("./assets/arrow.svg");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				width: 16px;
				height: 12px;
				top: 50%;
				transform: translateY(-50%);
				right: -35px;
			}

			&:hover,
			&:active {
				text-decoration: underline;
			}
		}
	}
}

// mailchimp

.gdpr-form-footer {
	color: $grey;

	.gdpr-mergeRow {
		margin: 10px 0;
		color: $grey;
	}

	.gdpr-content {
		margin: 0 -20px 0 -20px;
		padding: 20px;
		background: rgba(255, 255, 255, 0.8);
	}

	#gdpr-description {
		margin-top: 6px;
		margin-bottom: 0;
		padding-bottom: 11px;
	}

	.gdpr-content p {
		color: $grey;
		font-size: 13px;
		line-height: 1.5;
	}

	.gdpr-content .checkbox-group label {
		font-weight: normal;
		font-size: 12px;
		line-height: 16px;
	}

	.checkbox span {
		margin-left: 6px;
	}

	.gdpr-footer {
		margin: 0 -20px 20px -20px;
		padding: 0 20px;
		background: rgba(255, 255, 255, 0.9);
		overflow: auto;
		color: #6d6d6d;
	}

	.gdpr-footer p {
		font-size: 10px;
		line-height: 1.5;
		margin: 0;
		color: $grey;
	}

	.gdpr-footer a {
		color: $blue;
		font-size: 10px;
	}

	#gdpr-legal {
		padding: 0;
		margin: 10px 0;
	}

	.gdpr-footer img {
		width: 65px;
		float: left;
		margin-right: 15px;
	}
}
