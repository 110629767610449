// vars
$padding: 227px;
$padding-medium: 127px;
$padding-small: 27px;

$white: #ffffff;
$blue: #0b326b;
$red: #d11f38;
$grey: #4a4a4a;

$font-n: "interface", sans-serif;
$font-b: "interface bold", sans-serif;
$font: "interface", sans-serif;
