@mixin antialiasing($type: antialiased) {
	-webkit-font-smoothing: $type;
	font-smoothing: $type;
	font-smooth: always;
	-moz-osx-font-smoothing: grayscale;

	* {
		-webkit-font-smoothing: inherit;
		font-smoothing: inherit;
		font-smooth: inherit;
	}
}

@mixin ie() {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		@content;
	}
}
@mixin res($width, $dir: max) {
	@if $width== "mobile" {
		$width: 480;
	}

	@if $width== "tab-p" {
		$width: 768;
	}

	@if $width== "tab-l" {
		$width: 1024;
	}

	@if $width== "large" {
		$width: 1179;
	}

	@media (#{$dir}-width: $width + "px") {
		@content;
	}
}
